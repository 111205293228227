<template>
  <div>
    <v-card v-if="step===0">
      <v-card-title>
        {{ $t('Regulations') }}
      </v-card-title>
      <v-card-text>
        <component :is="reg"></component>
      </v-card-text>
      <v-card-text>
        <v-checkbox
          v-model="isAgreeTermsSharing"
          hide-details
        >
          <template #label>
            <div>
              {{ $t('AgreeTermsSharing') }}
            </div>
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-text>
        <v-btn
          :disabled="!isAgreeTermsSharing"
          class="mt-4"
          color="primary"
          block
          @click="step = 1"
        >
          {{ $t('Continue') }}
        </v-btn>
        <v-btn
          class="mt-4"
          outlined
          block
          @click="$router.back()"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card v-else-if="step===1">
      <v-form
        ref="chooseTimeRangeForm"
        @submit.prevent="handleChooseTimeRangeFormSubmit"
      >
        <v-card-title>
          {{ $t('ChooseTimeRange') }}
        </v-card-title>
        <v-card-text>
          <div>
            <DatePicker
              v-model="date"
              :label="$t('Day')"
              :rules="[validators.required]"
            />
          </div>
        </v-card-text>

        <v-card-text>
          <div>
            <TimePicker
              v-model="startTime"
              :label="$t('StartTime')"
              :rules="[validators.required]"
            />
          </div>
        </v-card-text>

        <v-card-text>
          <div>
            <TimePicker
              v-model="endTime"
              :label="$t('EndTime')"
              :rules="[validators.required]"
            />
          </div>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            block
            type="submit"
          >
            {{ $t('Continue') }}
          </v-btn>
          <v-btn
            class="mt-4"
            outlined
            block
            @click="step=0"
          >
            {{ $t('Back') }}
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card v-else>
      <v-form
        ref="topicInformationForm"
        @submit.prevent="handleTopicInformationFormSubmit"
      >
        <v-card-title>
          {{ $t('RegisterSkillShare') }}
        </v-card-title>
        <v-card-text>
          <strong>{{ $t('Time') }}</strong>: {{ enrichTopicTime(
            formatTimestampToDatetime(fromTime, 'DD/MM/YYYY HH:mm'),
            formatTimestampToDatetime(toTime, 'DD/MM/YYYY HH:mm')
          ) }}
        </v-card-text>
        <v-card-text>
          <strong>{{ $t('Speaker') }}</strong>: {{ userData.full_name }}
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="topicName"
            dense
            outlined
            :label="$t('Topic')"
            :placeholder="$t('Topic')"
            :rules="[validators.required]"
            hide-details="auto"
            validate-on-blur
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="maxSeat"
            dense
            outlined
            type="number"
            :label="$t('ExpectedParticipants')"
            :placeholder="$t('ExpectedParticipants')"
            :rules="[validators.required]"
            hide-details="auto"
            validate-on-blur
          />
        </v-card-text>

        <v-card-text>
          <v-textarea
            v-model="description"
            dense
            outlined
            :label="$t('Description')"
            :placeholder="$t('Description')"
            :rules="[validators.required]"
            hide-details="auto"
            validate-on-blur
          ></v-textarea>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              class="text-center"
            >
              <Upload
                v-model="imageUpload1"
                :auto-sizing="true"
              />
              <div>
                {{ $t('Image') }} 1
              </div>
            </v-col>
            <v-col
              cols="6"
              class="text-center"
            >
              <Upload
                v-model="imageUpload2"
                :auto-sizing="true"
              />
              <div>
                {{ $t('Image') }} 2
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          Chọn câu hỏi
        </v-card-title>
        <v-card-text>
          <template>
            <div class="px-2">
              <v-row
                v-for="question in questionList"
                :key="question.id_question"
                align="center"
              >
                <v-checkbox
                  v-model="question_assigned"
                  :value="question.id_question"
                  :label="question.title"
                  class="shrink me-2 mt-0"
                ></v-checkbox>
              </v-row>
            </div>
          </template>
        </v-card-text>
        <v-card-text>
          <v-btn
            color="primary"
            block
            type="submit"
            :loading="loading"
          >
            {{ $t('Save') }}
          </v-btn>

          <v-btn
            class="mt-4"
            outlined
            block
            @click="isFromReservation ? $router.back() : step = 1"
          >
            {{ $t('Back') }}
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { required } from '@core/utils/validation'
import { computed, ref, onMounted } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import Upload from '@/components/Upload/Upload.vue'
import store from '@/store'
import UploadService from '@/services/UploadService'
import SkillShareService from '@/services/SkillShareService'
import { useRouter } from '@core/utils'
import { enrichTopicTime } from '@/utils/skillShareHelper'

import Reg from '@/views/SkillShare/User/components/Regulations.vue'
import RegEn from '@/views/SkillShare/User/components/Regulations-en.vue'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default {
  components: { DatePicker, TimePicker, Upload },
  computed: {
    reg() {
      return this.$i18n.locale === 'en' ? RegEn : Reg
    },
  },
  setup() {
    const { router } = useRouter()
    const routerParams = router.currentRoute.params
    const isFromReservation = computed(() => routerParams.isFromReservation)
    const userData = ref({ ...store.getters['auth/currentUser'] })
    const chooseTimeRangeForm = ref(null)
    const topicInformationForm = ref(null)
    const step = ref(routerParams.step || 0)
    const isAgreeTermsSharing = ref(false)
    const date = ref(routerParams.date || '')
    const startTime = ref('')
    const endTime = ref('')
    const fromTime = ref(routerParams.startTime || '')
    const toTime = ref(routerParams.endTime || '')
    const maxSeat = ref(null)
    const topicName = ref('')
    const description = ref('')
    const imageUpload1 = ref({})
    const image1 = ref(null)
    const imageUpload2 = ref({})
    const image2 = ref(null)
    const loading = ref(false)
    const questionList = ref([])
    // eslint-disable-next-line camelcase
    const question_assigned = ref([])

    const handleChooseTimeRangeFormSubmit = () => {
      const isFormValid = chooseTimeRangeForm.value.validate()

      if (isFormValid) {
        fromTime.value = dayjs(`${date.value} ${startTime.value}`, 'YYYY-MM-DD HH:mm').unix()
        toTime.value = dayjs(`${date.value} ${endTime.value}`, 'YYYY-MM-DD HH:mm').unix()
        step.value = 2
      }
    }

    const handleTopicInformationFormSubmit = async () => {
      const isFormValid = topicInformationForm.value.validate()

      if (isFormValid) {
        loading.value = true
        try {
          if (imageUpload1.value.chosenFile) {
            await imageUpload1.value
              .asyncBase64('image/jpeg', 0.8)
              .then(async base64 => {
                await UploadService.uploadFile({
                  note: 'imageUpload1',
                  files: [base64],
                }).then(res => {
                  const imageUpload1Id = res.data?.data?.files?.[0]?.id_file
                  image1.value = imageUpload1Id
                })
              })
              .catch(error => {
                console.error(error)
                store.commit('snackbar/show', {
                  message: 'PleaseUpload',
                  type: 'error',
                })
              })
          }

          if (imageUpload2.value.chosenFile) {
            await imageUpload2.value
              .asyncBase64('image/jpeg', 0.8)
              .then(async base64 => {
                await UploadService.uploadFile({
                  note: 'imageUpload2',
                  files: [base64],
                }).then(res => {
                  const imageUpload2Id = res.data?.data?.files?.[0]?.id_file
                  image2.value = imageUpload2Id
                })
              })
              .catch(error => {
                console.error(error)
                store.commit('snackbar/show', {
                  message: 'PleaseUpload',
                  type: 'error',
                })
              })
          }

          const newTopicData = {
            name: topicName.value,
            start_time: fromTime.value,
            end_time: toTime.value,
            max_seat: maxSeat.value,
            description: description.value,
            image_1: image1.value,
            image_2: image2.value,
            question_assign: question_assigned.value,
          }

          await SkillShareService.createTopic(newTopicData)
          store.commit('snackbar/show', {
            message: 'Create event successfully',
            type: 'success',
          })
          router.push({ name: 'skill-share-list' })
        } catch (e) {
        } finally {
          loading.value = false
        }
      }
    }

    onMounted(() => {
      SkillShareService.getQuestionList().then(res => {
        questionList.value = res.data?.data
      })
    })

    return {
      userData,
      step,
      isAgreeTermsSharing,
      date,
      startTime,
      endTime,
      fromTime,
      toTime,
      enrichTopicTime,
      maxSeat,
      topicName,
      description,
      imageUpload1,
      imageUpload2,
      loading,
      question_assigned,
      questionList,

      validators: {
        required,
      },

      chooseTimeRangeForm,
      handleChooseTimeRangeFormSubmit,
      topicInformationForm,
      handleTopicInformationFormSubmit,

      isFromReservation,
    }
  },
}
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
  border: 1px solid #ccc;
  display: block !important;
  margin: 0 auto;
}
</style>
